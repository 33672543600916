@font-face{
    font-family: 'Ailerons';
    src: url('./fonts/subset-Ailerons-Regular.woff2') format('woff2'),
        url('./fonts/subset-Ailerons-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

*
{
    margin: 0;
    padding: 0;
}

html,
body
{
   background:#000000;
   overflow:   scroll;
   
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
    background:transparent;

}


a:link, a:visited {
    color: white;
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    position:relative;

  }

  a:hover, a:active {
    color: RoyalBlue;
    position:relative;
}


.loading-bar
{
    position: absolute;
    top: 80%;
    width: 100%;
    height: 10px;
    background: #ffffff;
    transform: scaleX(0.0);
    transform-origin: top left;
    transition: transform 0.5s;
    
}

.loading-bar.ended
{
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 1.5s ease-in-out;
}
.button{
    font-size: 25px;
    position:fixed;
    overflow-y:scroll;
    overflow-x:hidden;
    bottom: 20px;
    right: 20px;
    color: #ffffff;
    border: none;
    background-color: #000000;
    
}
  /* Darker background on mouse-over */
.button:hover {
    color: RoyalBlue;
}

  
.section
{
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;
    font-family: 'Ailerons', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    color: #ffeded;
    z-index: 1;
}
#portfolio{

position: absolute;
top:40%;
left:20px;
font-size: 0.8rem;
}


#projects{

position: absolute;
font-size: 3rem;
top:100%;
right:50px;
}

#contact{

position: absolute; /*  relative getirerek düzenleme yapılabilir mi?*/
font-size: 3rem;
top:200%;
left:50px;
}

h2{
    position:relative;
}